import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
    Label,
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import {PAGE_RESTRICTING_MESSAGE} from '../redux/layout/constants';
import { RiaFirmSearchState } from '../redux/firstClassData/dtos';
import APIClient from '../utils/API';
import RiaFirmAssetTypesAndAmountsFields from './SearchFormCards/RiaFirmAssetTypesAndAmountsFields';
import RiaFirmEmployeeAndRepCount from './SearchFormCards/RiaFirmEmployeeAndRepCount';
import AddressSearchFields from './SearchFormCards/AddressSearchFields';
import ConsumerDataFiltersFields from './SearchFormCards/ConsumerDataFiltersFields';
import IndividualTitleSelect from './SearchFormCards/IndividualExecutiveTitleSelect';


export default class RiaFirmSearchForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onFieldChange: PropTypes.func.isRequired,
        formState: PropTypes.instanceOf(RiaFirmSearchState).isRequired,
    };

    constructor(props) {
        super(props);
        this.apiClient = new APIClient();

        this.getTypeaheadOptions = this.getTypeaheadOptions.bind(this);
        this.formatCsv = this.formatCsv.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    getTypeaheadOptions = (fieldName, inputValue, callback) => {
        this.apiClient.getTypeaheadOptions(fieldName, inputValue)
            .then(response => {
                callback(response.data.results);
            });
    };

    formatCsv(value) {
        if (Array.isArray(value)) {
            return value.join(', ')
        }
        return value;
    }

    handleSelectChange(fieldName, selectedOptions) {
        let selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];

        this.props.onFieldChange({
            target: {
                name: fieldName,
                value: selectedValue,
            }
        });
    }

    render() {
        let formState = this.props.formState;

        let selectedFirmNames = formState.firm_names.map(firmName => {
            return {value: firmName, label: firmName};
        });
        let hasPermission = this.props.hasPermission

        return (
            <>
        <Form onSubmit={this.props.onSubmit} className={ !hasPermission ? "permission-card-section restricted" : "" } >
            <Card >
                <CardBody>
                    <h4 className="header-title">Firm Details</h4>

                    <Row className="mb-2">
                        <Col lg={12}>
                            <Row>
                                <Col lg={8}>

                                    <FormGroup>
                                        <AsyncSelect
                                            placeholder="Firm Names"
                                            isMulti
                                            value={selectedFirmNames || null}
                                            isClearable
                                            defaultOptions
                                            cacheOptions
                                            loadOptions={(inputValue, callback) => this.getTypeaheadOptions('ria_firm', inputValue, callback)}
                                            onChange={value => this.handleSelectChange('firm_names', value)}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Input type="text" name="crds" value={this.formatCsv(formState.crds)}
                                            onChange={this.props.onFieldChange} placeholder="CRD(s)" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <AddressSearchFields formState={formState} onChange={this.props.onFieldChange} formName={this.props.formName} showAddressTypeCheckboxes={false} />
                    <IndividualTitleSelect formState={formState} onChange={this.props.onFieldChange} formName={this.props.formName} />
                </CardBody>
            </Card>

            <RiaFirmAssetTypesAndAmountsFields onChange={this.props.onFieldChange} formState={this.props.formState} />

            <RiaFirmEmployeeAndRepCount onChange={this.props.onFieldChange} formState={this.props.formState} />

            <Card>
                <CardBody>
                    <ConsumerDataFiltersFields formState={formState} onChange={this.props.onFieldChange} />
                </CardBody>
            </Card>          
        </Form>
    { !hasPermission ?
            <div className="permission-card">
            <div className='card permission'>
                <h6 className="permission-header">{PAGE_RESTRICTING_MESSAGE}</h6>
            </div>
        </div>:""
    }
        </>
        );
    }
}
